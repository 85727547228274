<template>
	<div>
		<v-row class="mt-1 ml-2 mb-3">
			<v-col>
				<h5 class="text-center">GHANA REVENUE AUTHORITY</h5>
				<h5 class="text-center">DOMESTIC TAX REVENUE DIVISION</h5>
			</v-col>
			<v-col cols="12" md="2" sm="2" class="text-right">
				<!-- export payroll -->
				<v-menu transition="scale-transition" origin="center center" offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="primary" v-bind="attrs" v-on="on" @click="exportData">
							Export Payroll
							<v-icon class="pl-1" size="24"> mdi-export </v-icon>
						</v-btn>
					</template>
				</v-menu>
			</v-col>
		</v-row>

		<v-col>
			<h5 class="text-center">
				EMPLOYER'S MONTHLY TAX DEDUCTIONS SCHEDULE (P. A. Y. E)
			</h5>
		</v-col>

		<v-row>
			<v-col cols="12" md="2">
				<h6 class="mb-7 pt-2">CURRENT TAX OFFICE</h6>
				<h6 class="mb-6 pt-2">NAME OF EMPLOYER</h6>
				<h6 class="mb-6 pt-2">EMPLOYER'S TIN</h6>
			</v-col>
			<v-col cols="12" md="7">
				<div class="mb-8 mt-1">
					<div class="d-flex text-center" style="font-size: 14px">
						<div class="box w-50">LTO</div>
						<div class="box w-50">MTO</div>
						<div class="box w-50">STO</div>
						<div class="box w-100">TAKORADI</div>
					</div>
					<div>
						<em class="ems tick" style="font-size: 12px; font-weight: bold">(tick one)</em>
						<em class="ems tax-office" style="font-size: 12px; font-weight: bold">Name of Tax Office</em>
					</div>
				</div>
				<div class="box mb-6 text-center" style="font-size: 14px">
					<!-- AMALITECH GHANA LIMITED -->
					{{ title }}
				</div>
				<div class="box mb-6" style="font-size: 14px">C0026155419</div>
			</v-col>
			<v-col cols="12" md="3" class="d-flex align-items-center p-0">
				<div class="d-flex w-100">
					<v-col cols="6" class="text-right" style="font-size: small">FOR THE MONTH OF</v-col>
					<v-col cols="6" style="position: relative">
						<div class="box w-100" style="height: 20px"></div>
						<em class="ems date">(MM/YYYY)</em>
					</v-col>
				</div>
			</v-col>
		</v-row>

		<!-- Spinner -->
		<div v-if="isLoading">
			<spinner></spinner>
		</div>
		<!-- search -->
		<v-row>
			<v-col cols="12" sm="3">
				<v-text-field @keyup.enter="handleFilter" solo prepend-inner-icon="mdi-magnify" label="Search by Name"
					dense clearable flat @click:clear="resetData" color="primary" background-color="white lighten-4"
					v-model="filterForm.name"></v-text-field>
			</v-col>

			<v-col cols="12" sm="3" md="3">
				<v-select solo dense flat label="Search by Department" :items="departments" item-text="name"
					item-value="abbreviation" v-model="filterForm.department"></v-select>
			</v-col>

			<v-col cols="12" sm="3" md="3">
				<v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="filterForm.date"
					transition="scale-transition" offset-y max-width="290px" min-width="auto">
					<!-- filter payroll -->
					<template v-slot:activator="{ on, attrs }">
						<v-text-field dense solo flat background-color="white" placeholder="yyyy-mm"
							v-model="filterForm.date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
							v-on="on"></v-text-field>
					</template>
					<v-date-picker v-model="filterForm.date" type="month" no-title scrollable>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="menu = false">
							Cancel
						</v-btn>

						<v-btn text color="primary" @click="$refs.menu.save(filterForm.date)">
							OK
						</v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>
			<v-col cols="12" md="3" sm="3" class="d-flex justify-content-between">
				<v-btn color="primary" @click.prevent="handleFilter">filter</v-btn>
				<v-btn color="#eff" class="primary--text" @click.prevent="resetData">Clear</v-btn>
			</v-col>
		</v-row>
		<v-data-table id="gra-table" fixed-header height="80vh" :headers="headers" :items="graReport" disable-sort
			class="table-rounded elevation-2" :page.sync="page" @page-count="pageCount = $event"
			:items-per-page="itemsPerPage" hide-default-footer>
			<template v-slot:[`item.no`]="{ index }">
				{{ index + 1 + pageNumbering }}
			</template>
			<template v-slot:[`item.ghanaCard`]="{ item }">
				{{ item.employee?.employeeIdentification?.ghanaCard }}
			</template>
			<template v-slot:[`item.fullName`]="{ item }">
				{{ item.employee.displayName }}
			</template>
			<template v-slot:[`item.employeePosition`]="{ item }">
				{{ item.employee.employeePosition }}
			</template>
			<template v-slot:[`item.nonResident`]="{ item }">
				{{ item.employee.nonResident ? item.employee.nonResident : "N/A" }}
			</template>
			<template v-slot:[`item.basicSalary`]="{ item }">
				{{ formatFigure(item.employee.basicSalary) }}
			</template>
			<template v-slot:[`item.secondaryEmployment`]="{ item }">
				{{
						item.employee.secondaryEmployment
							? item.employee.secondaryEmployment
							: "N/A"
					}}
			</template>
			<template v-slot:[`item.cashEmolument`]="{ item }">
				{{ formatFigure(item.cashEmolument) }}
			</template>
			<template v-slot:[`item.accommodationElement`]="{ item }">
				{{ formatFigure(item.accommodationElement) }}
			</template>
			<template v-slot:[`item.vehicleElement`]="{ item }">
				{{ formatFigure(item.vehicleElement) }}
			</template>
			<template v-slot:[`item.totalAllowance`]="{ item }">
				{{ formatFigure(item.totalAllowance) }}
			</template>
			<template v-slot:[`item.nonCashBenefit`]="{ item }">
				{{ formatFigure(item.nonCashBenefit) }}
			</template>
			<template v-slot:[`item.initialBonus`]="{ item }">
				{{ formatFigure(item.initialBonus) }}
			</template>
			<template v-slot:[`item.assessableIncome`]="{ item }">
				{{ formatFigure(item.assessableIncome) }}
			</template>
			<template v-slot:[`item.chargeableIncome`]="{ item }">
				{{ formatFigure(item.chargeableIncome) }}
			</template>
			<template v-slot:[`item.employeeSSF`]="{ item }">
				{{ formatFigure(item.employeeSSF) }}
			</template>
			<template v-slot:[`item.employeeTierThreePre`]="{ item }">
				{{ formatFigure(item.employeeTierThreePre) }}
			</template>
			<template v-slot:[`item.taxRelief`]="{ item }">
				{{ formatFigure(item.taxRelief) }}
			</template>
			<template v-slot:[`item.totalRelief`]="{ item }">
				{{ formatFigure(item.employeeSSF + item.taxRelief) }}
			</template>
			<template v-slot:[`item.paye`]="{ item }">
				{{ formatFigure(item.paye) }}
			</template>
			<template v-slot:[`item.severancePay`]="{ item }">
				{{ formatFigure(item.severancePay) }}
			</template>
			<template v-slot:[`item.taxableIncome`]="{ item }">
				{{ formatFigure(item.taxableIncome) }}
			</template>
			<template v-slot:[`item.overtimeIncome`]="{ item }">
				{{ formatFigure(item.overtimeIncome) }}
			</template>
			<template v-slot:[`item.overtimeTax`]="{ item }">
				{{ formatFigure(item.overtimeTax) }}
			</template>
			<template v-slot:[`item.totalTaxOnBonus`]="{ item }">
				{{ formatFigure(item.totalTaxOnBonus) }}
			</template>
			<template v-slot:[`item.excessBonus`]="{ item }">
				{{ formatFigure(item.excessBonus) }}
			</template>
			<template v-slot:[`item.totalTax`]="{ item }">
				{{ formatFigure(item.paye + item.totalTaxOnBonus) }}
			</template>
			<template slot="body.append">
				<tr class="primary--text footer">
					<th class="subtitle-2" id="totals">TOTALS</th>
					<th class="subtitle-2" id="tin"></th>
					<th class="subtitle-2" id="name"></th>
					<th class="subtitle-2" id="position"></th>
					<th class="subtitle-2" id="non-resident"></th>
					<th class="subtitle-2 table-bordered" id="basic-salary">
						{{ formatFigure(masterPayrollTotals.basicSalary) }}
					</th>
					<th class="subtitle-2 table-bordered" id="secondary-employment"></th>
					<th class="subtitle-2 table-bordered" id="ssf">
						{{ formatFigure(masterPayrollTotals.employeeSSF) }}
					</th>
					<th class="subtitle-2 table-bordered" id="third-tier">
						{{ formatFigure(masterPayrollTotals.employeeTierThreePre) }}
					</th>
					<th class="subtitle-2 table-bordered" id="cash-allowances">
						{{ formatFigure(masterPayrollTotals.allowance) }}
					</th>
					<th class="subtitle-2 table-bordered" id="bonus-Total">
						{{ formatFigure(masterPayrollTotals.bonusTotal) }}
					</th>
					<th class="subtitle-2 table-bordered" id="final-tax">
						{{ formatFigure(masterPayrollTotals.bonusTax) }}
					</th>
					<th class="subtitle-2 table-bordered" id="excess-bonus">
						{{ formatFigure(masterPayrollTotals.excessBonus) }}
					</th>
					<th class="subtitle-2 table-bordered" id="cash-emolument">
						{{ formatFigure(masterPayrollTotals.cashEmolument) }}
					</th>
					<th class="subtitle-2 table-bordered" id="accommodation-element"></th>
					<th class="subtitle-2 table-bordered" id="vehicle-element"></th>
					<th class="subtitle-2 table-bordered" id="non-cash-benefit"></th>
					<th class="subtitle-2 table-bordered" id="assessable-income">
						{{ formatFigure(masterPayrollTotals.grossSalary) }}
					</th>
					<th class="subtitle-2 table-bordered" id="deductible-reliefs">
						{{ formatFigure(masterPayrollTotals.taxRelief) }}
					</th>
					<th class="subtitle-2 table-bordered" id="total-reliefs">
						{{ formatFigure(masterPayrollTotals.totalRelief) }}
					</th>
					<th class="subtitle-2 table-bordered" id="chargeable-income">
						{{ formatFigure(masterPayrollTotals.taxableIncome) }}
					</th>
					<th class="subtitle-2 table-bordered" id="tax-deductible">
						{{ formatFigure(masterPayrollTotals.paye) }}
					</th>
					<th class="subtitle-2 table-bordered" id="overtime-income"></th>
					<th class="subtitle-2 table-bordered" id="overtime-tax"></th>
					<th class="subtitle-2 table-bordered" id="total-tax-payable">
						{{ formatFigure(masterPayrollTotals.paye + masterPayrollTotals.bonusTax) }}
					</th>
					<th class="subtitle-2 table-bordered" id="severance-pay-paid"></th>
					<th class="subtitle-2 table-bordered" id="remarks"></th>
				</tr>
			</template>
			<template v-slot:no-data> NO DATA AVAILABLE ! </template>
		</v-data-table>
		<div class="text-center pt-2">
			<v-pagination v-model="page" :length="totalPages" @input="paginatePage()" circle></v-pagination>
			<template> </template>
		</div>

		<v-row>
			<h6 class="text-center mt-4">DECLARATION:</h6>
			<div>
				I, ________________________________________________ hereby declare
				that the information provided above is true and correct
			</div>
			<v-row class="d-flex mt-5">
				<v-col cols="4" class="fill">
					<div class="box w-100" style="height: 30px"></div>
					<span>POSITION</span>
				</v-col>
				<v-col cols="4" class="fill">
					<div class="box w-100" style="height: 30px"></div>
					<span>SIGNATURE</span>
				</v-col>
				<v-col cols="4" class="fill">
					<div class="box w-100" style="height: 30px"></div>
					<span>DATE</span>
				</v-col>
			</v-row>
		</v-row>
	</div>
</template>

<script>
import { EXPORT_GRA_REPORT } from "../../services/graphql/queries/payroll_tier_queries";
import moment from "moment";
import { Parser } from "json2csv";
import generalPageMixin from "../../services/mixins/generalPageMixin";
import methMixin from "../../services/mixins/paginateMethodMixin";
import helpers from "../../services/helpers";
import { createHeaders } from "../../services/functions";
export default {
	mixins: [helpers, generalPageMixin, methMixin],
	data: () => ({
		menu: false,
		itemsPerPage: 20,
		dialog: false,
		dialogDelete: false,
		isLoading: false,
		departments: [
			{ name: "Amalitech Ghana", abbreviation: "" },
			{ name: "Amalitech Training", abbreviation: "TRN" },
		],
		storePath: "report",
		sourcePath: "report",
		actionType: "fetchPayrollReport",
		filterForm: new Form({
			name: "",
			department: "",
			date: "",
		}),
		title: "AMALITECH GHANA LIMITED",
		queryType: "yearMonth",
		headers: createHeaders("graReport"),
	}),
	computed: {
		graReport() {
			return this.$store.getters["report/allPayrollReport"];
		},
		masterPayrollTotals() {
			return this.$store.getters["report/getMasterPayrollTotals"];
		},
		pageNumbering() {
			if (this.page === 0) {
				return 0;
			} else {
				return this.page * 20 - 20;
			}
		},
		data() {
			let today = new Date();
			let date =
				today.getFullYear() +
				"-" +
				("0" + (today.getMonth() + 1)).slice(-2);
			return {
				yearMonth: date,
			};
		},
	},
	async created() {
		this.isLoading = true;
		this.filterForm.date = this.data.yearMonth;
		await this.initialize();
		this.isLoading = false;
	},

	methods: {
		async initialize() {
			await this.$store.dispatch("report/fetchAmalitechGraReport", {
				page: this.page || 1,
				yearMonth: this.filterForm.date,
				department: this.filterForm.department,
				searchName: this.filterForm.name || "",
			})

			await this.$store.dispatch("report/fetchMasterPayrollTotals", {
				data: {
					yearMonth: this.filterForm.date,
					startWith: this.filterForm.department,
				},
			});
		},

		async resetData() {
			this.isLoading = true;
			this.filterForm.reset();
			this.filterForm.date = this.data.yearMonth;
			this.title = "AMALITECH GHANA LIMITED";
			await this.initialize();
			this.isLoading = false;
		},

		getCurrentMonthYearFromDate(date) {
			const year = new Date(date).getFullYear();
			const month = new Date(date).toLocaleString("default", {
				month: "long",
			});
			return `${year} ${month}`;
		},



		async exportGra() {
			this.isLoading = true;
			const data = {
				name: this.filterForm.name || "",
				startWith: this.filterForm.department,
				yearMonth: `${this.filterForm.date}`,
			};

			const date = moment(data.yearMonth).format("MMM YYYY");
			this.$apollo
				.query({
					query: EXPORT_GRA_REPORT,
					variables: {
						employeeName: data.name,
						yearMonth: data.yearMonth,
						startWith: data.startWith,
						responseType: "blob",
						headers: { Accept: "multipart/form-data" },
					},
				})
				.then((response) => {
					const title = `GRA REPORT FOR - ${date}.xlsx`;
					const fileLink = response.data.exportGRAReport;
					const a = Object.assign(document.createElement("a"), {
						href: fileLink,
						download: title,
					});
					a.click();
					a.remove();
					this.isLoading = false;
				})
				.catch(() => {
					this.isLoading = false;
				});
		},

		async exportData() {
			this.isLoading = true;
			const data = {
				name: this.filterForm.name || "",
				startWith: this.filterForm.department,
				yearMonth: `${this.filterForm.date}`,
			};
			const date = moment(data).format("MMM YYYY");

			const csvContent = this.graReport.map((item, index) => ({
				"Ser. No:": index + 1,
				"TIN (11 characters)/Ghana Card:": item.employee.employeeIdentification?.ghanaCard || "",
				"Name Of Employee:": item.employee.displayName,
				"Position:": item.employee.employeePosition,
				"Non-Resident (Y / N):": item.employee.nonResident || "N/A",
				"Basic Salary:": item.employee.basicSalary,
				"Secondary Employment (Y / N):": item.employee.secondaryEmployment || "N/A",
				"Social Security Fund:": item.employeeSSF,
				"Third Tier:": item.employeeTierThreePre,
				"Cash Allowances:": item.totalAllowance,
				"Bonus Income(up to 15% of Annual Basic salary):": item.initialBonus,
				"Final Tax on Bonus Income:": item.totalTaxOnBonus,
				"Excess Bonus:": item.excessBonus,
				"Total Cash emolument (6+10+13):": item.cashEmolument,
				"Accommodation Element:": item.accommodationElement,
				"Vehicle element:": item.vehicleElement,
				"Non Cash Benefit:": item.nonCashBenefit,
				"Assessable Income:": item.assessableIncome,
				"Deductible Reliefs:": item.taxRelief,
				"Total Reliefs:": item.employeeSSF + item.taxRelief,
				"Chargeable Income:": item.chargeableIncome,
				"Tax Deductible:": item.paye,
				"Overtime Income:": item.overtimeIncome,
				"Overtime Tax:": item.overtimeTax,
				"Total Tax Payable to GRA:": item.paye + item.totalTaxOnBonus,
				"Severance pay paid:": item.severancePay || "",
			}));

			const title = `GRA REPORT FOR - ${date}.csv`;
			const parser = new Parser();
			const csv = parser.parse(csvContent);
			const blob = new Blob([csv], { type: "text/csv" });
			const a = document.createElement("a");
			a.href = window.URL.createObjectURL(blob);
			a.download = title;
			a.click();
			a.remove();
			this.isLoading = false;
		},

		async handleFilter() {
			this.isLoading = true;

			await this.$store.dispatch("report/fetchAmalitechGraReport", {
				page: 1,
				searchName: this.filterForm.name || "",
				department: this.filterForm.department,
				yearMonth: this.filterForm.date,
			});
			await this.$store.dispatch("report/fetchMasterPayrollTotals", {
				data: {
					yearMonth: this.filterForm.date,
					startWith: this.filterForm.department,
				},
			});
			this.isLoading = false;
			this.toggleTitle();
		},
		toggleTitle() {
			const departmentTitles = {
				"": "AMALITECH GHANA LIMITED",
				"TRN": "AMALITECH TRAINING",
			};

			this.title = departmentTitles[this.filterForm.department];
		},
	},
};
</script>

<style>
.v-toolbar__title {
	font-weight: bolder;
	font-size: 1.2rem;
}

.box {
	border: 2px solid black;
}

.ems {
	position: absolute;
	font-size: 12px;
	font-weight: bold;
}

.tick {
	left: 30%;
}

.tax-office {
	right: 38%;
}

.date {
	right: 30%;
}

.fill {
	text-align: center;
	font-size: 14px;
	font-weight: bold;
}

#gra-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(3),
#gra-table>.v-data-table__wrapper>table>thead>tr>th:nth-child(3) {
	position: sticky !important;
	position: -webkit-sticky !important;
	left: 0;
	z-index: 6;
	background: white;
}

#gra-table>.v-data-table__wrapper>table>thead>tr>th:nth-child(3) {
	z-index: 7 !important;
	background: #f5f6fa;
}
</style>
